

export class Util {

    public static pad2Digits(char){

        return char.toString().padStart(2, '0')

    }


    public static dateToYMDString(date){

        return date.getFullYear() + '-' + (Util.pad2Digits(date.getMonth()+1)) + '-' + Util.pad2Digits(date.getDate())

    }

    public static dateToYMDSlashString(date){

        return date.getFullYear() + '/' + (Util.pad2Digits(date.getMonth()+1)) + '/' + Util.pad2Digits(date.getDate())

    }

    public static replaceAll(substring, replacement, originalString){
        /**
         * Replace all occurrences of a substring in a given string
         */
        // Todo: Update 'lib' in tsconfig.json to ES2021 to support built in replaceAll()
        return originalString.replace(new RegExp(substring, 'g'), replacement);
    }

}

