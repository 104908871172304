import {useCallback, useEffect, useRef, useState} from 'react';
import Button from '@amzn/awsui-components-react/polaris/button';
import Header from '@amzn/awsui-components-react/polaris/header';
import FondueApiFactory from '../../fondue-api/FondueApiFactory';
import { Report, ReportItem, FondueApi } from '../../fondue-api/generated-src';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import {Box, ButtonDropdown, ColumnLayout, Container, Grid} from "@amzn/awsui-components-react/polaris";
import Form from "@amzn/awsui-components-react/polaris/form";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import Textarea from '@amzn/awsui-components-react/polaris/textarea';
import Input from "@amzn/awsui-components-react/polaris/input";
import Select from '@amzn/awsui-components-react/polaris/select';
import { useSelect, useMultiselect, useInput } from '../hooks';
import { States } from "../../common/States";
import { getMidwayJwtToken } from "../../auth/MidwayJwtToken";
import Checkbox from '@amzn/awsui-components-react/polaris/checkbox';
import { GetReportItemTypes, IsDerReport } from '../../common/ReportTypes';
import DerReportItemForm from './custom/DerReportItemForm';
import StatusIndicator from "@amzn/awsui-components-react/polaris/status-indicator";
import Table from "@amzn/awsui-components-react/polaris/table";
import {DateUtil} from "../../util/DateUtil";
import {Util} from "../../util/Util";

export interface EditMetricFormProps {
    setState: (state: States) => void;
    report: Report;
    reportItem: ReportItem | null;
    setAddDisabled: (x: boolean) => void;
    setEditDisabled: (x: boolean) => void;
    setAddEditInfoDisabled: (x: boolean) => void;
    setRefreshTable: (refresh: boolean) => void;
}

export default function({setState, report, reportItem, setAddDisabled, setEditDisabled, setAddEditInfoDisabled, setRefreshTable}: EditMetricFormProps) {
  Object.freeze(Object.prototype);
  const [itemID, setItemID] = useState("");
  const [itemName, setItemName] = useState("");
  const [itemQuery, setItemQuery] = useState("");
  const [itemDescription, setItemDescription] = useState("");
  const [itemFormat, setItemFormat] = useState("");
  const [selectionOption, setSelectionOption] = useState({ "value": reportItem ? reportItem!.type : ""})
  const [disableQuery, setDisableQuery] = useState(false);
  const [disableFormat, setDisableFormat] = useState(false);
  const [formButtonsDisabled, setFormButtonsDisabled] = useState(false);
  const [wowEnabled, setWowEnabled] = useState(false);
  const [disableWow, setDisableWow] = useState(false);
  const [wowEnabledString, setWowEnabledString] = useState('false');

  const itemTypeOptions = GetReportItemTypes(report)

  const [disableRunQueryAndClear, setDisableRunQueryAndClear] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [queryResult, setQueryResult] = useState("");

  let runQueryStartDateTime = new Date(); // Needed to save query start time to calculate elapsed time
  const [elapsedTime, setElapsedTime] = useState("0:0");
  const lastTenWeekDropdownItems = DateUtil.dateListToItemDictionaryHyphen(DateUtil.getLastNSundayDatesFromGivenSunday(Util.dateToYMDString(DateUtil.getLastSundayDate()), 10))
  const [runQueryWBRDate, setRunQueryWBRDate] = useState(lastTenWeekDropdownItems[0]['text']);

  const [showRunQueryStatusSuccess, setShowRunQueryStatusSuccess] = useState(false);
  const [showRunQueryStatusFail, setShowRunQueryStatusFail] = useState(false);
  const [loadingRunQuery, setLoadingRunQuery] = useState(false);
  const [disableCancelQuery, setDisableCancelQuery] = useState(true);
  const [loadingCancelQuery, setLoadingCancelQuery] = useState(false);
  const [runningQueryId, setRunningQueryId] = useState("");
  const [runQueryInfoText, setRunQueryInfoText] = useState("");

  const querySuccessStatuses = ['FINISHED']
  const queryFailStatuses = ['FAILED', 'FORCED TIMEOUT', 'ABORTED', 'ERROR'] // 'ERROR' is a
    // custom failure response. Please refer 'poll_query()' handler in backend for more info.
  const queryDoneStatuses = [...querySuccessStatuses, ...queryFailStatuses]


  /**Returns if should allow empty sql query */
  function AllowEmptySqlQuery(): boolean {
    return IsDerReport(report); // Allow for IAM DER
  }

  async function saveReportItem(reportItemConfig?: {}) {
    await getMidwayJwtToken();
    const itemType = selectionOption['value'];
    // If Item is of type header and name not provided, setState to invalid input
    if(itemType === 'header' && itemName.trim() === ""){
        setState(States.invalid)
        return;
    }
    // If Item is of type query and query or name is not provided, setState to invalid input
    if(!AllowEmptySqlQuery() && itemType !== 'header' && (itemQuery.trim() === "" || itemName.trim() ==="")){
        setState(States.invalid)
        return;
    }

    // If Item is of type query_multiple_result and no format is provided, setState to invalid input
    if(itemType === 'query_multiple_result' && itemFormat.trim() === ""){
        setState(States.invalid)
        return;
    }

    const FondueApi = FondueApiFactory();
    if (reportItem){
        // Update reportItem object
        reportItem.id = itemID;
        reportItem.description = itemDescription;
        reportItem.name = itemName;
        reportItem.type = selectionOption['value'];
        reportItem.query = itemQuery;
        reportItem.format = itemFormat;
        reportItem.report_item_config =  {
            ...reportItemConfig,
            wow: wowEnabledString
        }

        // Add query and/or format for query item
        if(itemType !== 'header'){
            reportItem['query'] = itemQuery;

            // Add format for multiple result type
            if(itemType === 'query_multiple_result'){
                reportItem['format'] = itemFormat;
            }
        }

        setState(States.updatingReportItem);
        setFormButtonsDisabled(true);

        // Calling updateReportItem
        await FondueApi.updateReportItem(reportItem.id, reportItem)
            .then((response) => {
                setState(States.updateReportItemSuccess);
            })
            .catch((e)=> {
                setState(States.updateReportItemFailed);
        });
        setFormButtonsDisabled(false);
        setRefreshTable(true);

        // Closing Modal after submission of update report item
        setAddEditInfoDisabled(false);
        setAddDisabled(true);
        setEditDisabled(true);

    }
  }

  function updateFormOnItemTypeSelect(itemType){
    setSelectionOption(itemTypeOptions[itemType]);
    if(itemType === 'header') {
      setDisableQuery(true);
      setDisableFormat(true);
      setDisableWow(true);
    }
    // Disable format field for query_single_result
    else {
      setDisableQuery(false);
      if(itemType === 'query_multiple_result'){
          setDisableFormat(false);
          setDisableWow(true);
      }
      else{
          setDisableFormat(true);
          setDisableWow(false);
      }
    }
  }

    function updateWowEnabledString(wowEnabled){
        if(wowEnabled === true) {
            setWowEnabledString('true')
            setWowEnabled(true)
        }
        else {
            setWowEnabledString('false')
            setWowEnabled(false)
        }
    }

  function cancelAddItem() {
    // Clear Form
    setItemName('');
    setItemID('');
    setItemQuery('');
    updateFormOnItemTypeSelect('');
    setItemDescription('');
    setWowEnabled(false);
    // Show info
    setAddEditInfoDisabled(false);
    setAddDisabled(true);
    setEditDisabled(true);
  }

  function resetFields() {
    if (reportItem){
        setItemName(reportItem.name);
        setItemID(reportItem.id);
        setItemQuery((reportItem.query) ? (reportItem.query) : (''));
        const currentItemType = itemTypeOptions[(reportItem) ? (reportItem.type) : ('')];
        if (currentItemType){
            updateFormOnItemTypeSelect(currentItemType.value);
        }
        setItemDescription(reportItem.description ? (reportItem.description) : (''));
        setWowEnabled(!!(reportItem.report_item_config && reportItem.report_item_config.wow === 'true'));
    }
  }

    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );

    async function clearRunQueryStatus() {
      setElapsedTime('0:0');
      setShowRunQueryStatusSuccess(false);
      setShowRunQueryStatusFail(false);
      setQueryResult('')
      setShowResult(false);
      setDisableCancelQuery(true);
      setRunQueryInfoText('');
    }

    async function cancelRunQuery() {

        setLoadingRunQuery(false);
        setDisableCancelQuery(true);
        setLoadingCancelQuery(true);
        setRunQueryInfoText('Cancelling query');

        const FondueApi = FondueApiFactory();
        await getMidwayJwtToken();

        // Calling cancelRunQuery
        await FondueApi.cancelReportItemQuery(itemID, runningQueryId, report.id)
            .then((response) => {
                if(response.data['status']){
                    setRunQueryInfoText('Successfully cancelled running query. Now waiting for result');
                }
            })
            .catch((e)=> {
                setRunQueryInfoText('Failed to cancel running query');
            });

        setLoadingCancelQuery(false);

    }

    async function pollRunQueryResult(query_id: string) : Promise<boolean> {
        const FondueApi = FondueApiFactory();
        await getMidwayJwtToken();

        // Todo: Figure out way to return correct boolean from .then()=>{} or .catch()=>{}. Using this variable for now.
        let returnPollResultStatus = false;

        // Calling getReportItemQueryStatus
        await FondueApi.getReportItemQueryStatus(itemID, query_id, selectionOption['value'], itemFormat, report.id)
            .then((response) => {

                // Check if query has finished running
                if(queryDoneStatuses.indexOf(response.data['status']) !== -1){
                    // Show result
                    setQueryResult(response.data['result'])
                    setShowResult(true);

                    if (querySuccessStatuses.indexOf(response.data['status']) !== -1){
                        setShowRunQueryStatusSuccess(true);
                    } else if (queryFailStatuses.indexOf(response.data['status']) !== -1){
                        setShowRunQueryStatusFail(true);
                    }
                    returnPollResultStatus = true;
                }
            })
            .catch((e)=> {
                // Show result specifically to handle 'ERROR' status message. See comment on
                // 'queryFailStatuses' const above for more info.
                const error_message = JSON.parse(e.response.data['message'])
                setQueryResult(error_message['result']);
                setShowResult(true);
                setShowRunQueryStatusFail(true);
                // Return 'true' to stop polling loop in 'runQuery()' method
                returnPollResultStatus = true;
            });

        return returnPollResultStatus
    }

  async function runQuery() {
    if (reportItem){
        // Reset statuses and show 'in-progress' components
        setShowRunQueryStatusSuccess(false);
        setShowRunQueryStatusFail(false);
        setDisableCancelQuery(true);
        setLoadingRunQuery(true);
        setDisableRunQueryAndClear(true);
        setRunQueryInfoText('Submitting query to IAM DW');

        // Initiate runQuery feature time - to be able to show elapsed time
        runQueryStartDateTime = new Date();

        // Update time every 1 sec (to show elapsed time)
        const runQueryElapsedTimeInterval = setInterval(() => {
            setElapsedTime(DateUtil.getElapsedTime(runQueryStartDateTime, new Date()));
        }, 1000);

        await getMidwayJwtToken();
        const FondueApi = FondueApiFactory();

        // Update the '.query' to latest text present in text editor
        reportItem.query = Util.replaceAll('IAM_WBR_DT', runQueryWBRDate, itemQuery);

        // Calling runQuery
        await FondueApi.runReportItemQuery(reportItem.id, reportItem)
            .then(async (response) => {
                // Submitting query to Redshift was success
                // Now start polling result
                setRunQueryInfoText('Waiting for result');
                var gotRunQueryPollResult: boolean = false;
                setRunningQueryId(response.data['query_id']);
                setDisableCancelQuery(false);

                while (!gotRunQueryPollResult) {
                    gotRunQueryPollResult = await pollRunQueryResult(response.data['query_id']);

                    if (!gotRunQueryPollResult){
                        // Wait for 5 sec before retrying
                        await delay(5000);
                    }
                }
            })
            .catch((e)=> {
                setShowRunQueryStatusFail(true);
            });

        setRunQueryInfoText('');
        setDisableRunQueryAndClear(false);
        clearInterval(runQueryElapsedTimeInterval);
        setLoadingRunQuery(false);
    }
  }

  useEffect(() => {
    setItemName((reportItem) ? (reportItem.name) : (''));
    setItemID((reportItem) ? (reportItem.id) : (''));
    setItemQuery((reportItem && reportItem.query) ? (reportItem.query) : (''));
    const currentItemType = itemTypeOptions[(reportItem) ? (reportItem.type) : ('')];
    if (currentItemType){
      updateFormOnItemTypeSelect(currentItemType.value);
      if(currentItemType.value == 'query_multiple_result'){
        setItemFormat((reportItem && reportItem.format) ? (reportItem.format) : (''));
      }
    }
    setItemDescription((reportItem && reportItem.description) ? (reportItem.description) : (''));
    setWowEnabled(!!(reportItem && reportItem.report_item_config && reportItem.report_item_config.wow === 'true'));
  }, [reportItem]);

  return (
    <Container
            header={
                <Header variant="h2" description="">
                    Edit Item
                </Header>
            }
        >
            {
                IsDerReport(report)
                ? <DerReportItemForm clearFields={cancelAddItem} createReportItem={saveReportItem} disabled={formButtonsDisabled} isEditing={true} resetFields={resetFields}
                                    itemDescriptionState={[itemDescription, setItemDescription]}
                                    itemNameState={[itemName, setItemName]}
                                    itemQueryState={[itemQuery, setItemQuery]}
                                    reportItem={reportItem} 
                                    metricTypeState={[selectionOption, (event) => {}]}
                                    itemTypeOptions={itemTypeOptions} />
                :<Form
                    actions={
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button id="cancel" variant="normal" onClick={cancelAddItem} disabled={formButtonsDisabled}>
                                Cancel
                            </Button>
                            <Button id="reset" variant="normal" onClick={resetFields} disabled={formButtonsDisabled}>
                                Reset
                            </Button>
                            <Button id="submit" variant="primary" onClick={() => saveReportItem()} disabled={formButtonsDisabled}>
                                Save
                            </Button>
                        </SpaceBetween>
                    }
                >
                <SpaceBetween size="m">
                <FormField
                        id='item_id'
                        label={
                            <span>
                                Item ID
                            </span>
                        }
                        description={itemID}
                />
                    <FormField
                        id='metrictype'
                        label="Item Type">
                        <Select selectedOption={selectionOption}
                                placeholder="Select metric type"
                                options={Object.keys(itemTypeOptions).map((itemType) => { return itemTypeOptions[itemType] })}
                                onChange={event => {
                                    updateFormOnItemTypeSelect(event.detail.selectedOption.value);
                                }}/>
                    </FormField>
                    <FormField
                        id='editname'
                        label="Item Name">
                        <Input
                            value = {itemName}
                            onChange={(event) =>
                                setItemName(event.detail.value)
                            }
                        />
                    </FormField>
                    <FormField
                        id='description'
                        label={
                            <span>
                                Item Description <i> - optional </i>{" "}
                            </span>
                        }
                    >
                        <Textarea
                            value={itemDescription}
                            rows={3}
                            onChange={({detail}) =>
                                setItemDescription(detail.value)}
                        />
                    </FormField>
                    <FormField
                        id='query'
                        label={
                            <span>
                                SQL Query  <i> - required for query type </i>{" "}
                            </span>
                        }
                        description="SQL query that defines your item.  Only applies to items of query_single_result
                                    and query_multiple_result type. IAM_WBR_DT will resolve to Sunday date of a given
                                    weeks run"
                    >
                        <Textarea
                            disabled={disableQuery}
                            value={itemQuery}
                            rows={15}
                            onChange={({detail}) =>
                                setItemQuery(detail.value)}
                            placeholder={"SELECT count(distinct resource_share_id) FROM ram.resource_shares WHERE end_date = CAST('IAM_WBR_DT' as DATE);"}
                        />
                    </FormField>
                    <FormField
                        id='format'
                        label={
                            <span>
                                Query Format <i> - required for query_multiple_result </i>{" "}
                            </span>
                        }
                        description="Query format for query_multiple_result. If more than one column is defined in query
                                    please provide string of format with {} around each column. Example {account} - {count}"
                    >
                        <Input
                            disabled={disableFormat}
                            value = {itemFormat}
                            onChange={event =>
                                setItemFormat(event.detail.value)}
                            placeholder={"{account} - {count}"}
                        />
                    </FormField>
                    <FormField
                        id = 'additionalmetriccomponents'
                        label={
                            <span>
                                Additional Item Components
                            </span>
                        }
                        description="Select additional calculations to add to the item"
                    >
                        <ColumnLayout columns={2} variant='default'>
                            <SpaceBetween size="xs">
                                <Checkbox
                                    id='wowcheckbox'
                                    disabled={disableWow}
                                    onChange={({ detail }) =>
                                    updateWowEnabledString(detail.checked)
                                    }
                                    checked={wowEnabled}
                                    >
                                    wow
                                </Checkbox>
                            </SpaceBetween>
                        </ColumnLayout>
                    </FormField>

                    <Grid
                        id='runQueryGrid'
                        disableGutters
                        gridDefinition={[
                            { colspan: 10 },
                            { colspan: 2 }
                        ]}
                    >
                        <SpaceBetween id="runQueryElements" direction="horizontal" size="m">
                            <Button id="runquery" variant="normal" iconName="caret-right-filled"
                                    onClick={runQuery} disabled={disableRunQueryAndClear} loading={loadingRunQuery}>
                                Run Query
                            </Button>

                            <ButtonDropdown
                                id="runQueryWBRDropdown"
                                ariaLabel={runQueryWBRDate}
                                items={lastTenWeekDropdownItems}
                                disabled={disableRunQueryAndClear}
                                onItemClick={(event) => setRunQueryWBRDate(event.detail.id)}
                            >
                                {runQueryWBRDate}
                            </ButtonDropdown>

                            <SpaceBetween direction="horizontal" size="m">
                                <Box variant="p" padding={{left: "s", right: "s"}}>{elapsedTime}</Box>
                                <Button id="cancelRunQuery" variant="normal" onClick={cancelRunQuery}
                                        disabled={disableCancelQuery} loading={loadingCancelQuery}>
                                    Cancel
                                </Button>
                            </SpaceBetween>

                            {showRunQueryStatusSuccess ? <StatusIndicator>{elapsedTime} - Complete for {runQueryWBRDate}</StatusIndicator> : ''}
                            {showRunQueryStatusFail ? <StatusIndicator type="error">{elapsedTime} - Failed for {runQueryWBRDate}</StatusIndicator> : ''}

                        </SpaceBetween>

                        <Box float="right">
                            <Button id="clear-run-query" variant="link" disabled={disableRunQueryAndClear}
                                    onClick={clearRunQueryStatus}>Clear</Button>
                        </Box>

                        <br/>
                        <p><i>{runQueryInfoText}</i></p>

                    </Grid>
                        {
                            showResult &&
                            (<Table
                                id="run-query-result"
                                columnDefinitions={[
                                    {
                                        id: "result",
                                        header: "Result",
                                        cell: item => item.result || "-"
                                    }
                                ]}
                                items={[
                                    {
                                        result: queryResult
                                    }
                                ]}
                            />)
                        }
                    </SpaceBetween>
                </Form>
            }
        </Container>
  );
};
