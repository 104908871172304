import { Form, SpaceBetween, Button, FormField, Input, Textarea, Toggle, Checkbox, Select, SelectProps } from "@amzn/awsui-components-react";
import React, { useEffect, useState } from "react";
import { ReportItem } from "../../../fondue-api/generated-src";
import { IsDerReportItemType } from '../../../common/ReportTypes';

const EmptyFilterError = "Cannot be empty";

const PolicyDEROutputTypes = {
    counts: { value: "counts", label: "Policy Counts" },
    account_numbers: { value: "account_numbers", label: "Account Numbers", disabled: false },
    policy_ids: { value: "policy_ids", label: "Policy Ids", disabled: false},
    all_columns: { value: "all_columns", label: "All Columns", disabled: false }
};

const PolicyDEREffectTypes = {
    allow: { value: "allow", label: "Allow" },
    deny: { value: "deny", label: "Deny" }
};

const PolicyDataPartitions = {
    iad: { value: "iad", label: "IAD (Classic)" }
}

export interface DerReportProps {
    clearFields: () => void;
    createReportItem: (reportItemConfig?: {}) => void;
    disabled: boolean;
    metricTypeState: [SelectProps.Option, (any) => void];
    itemNameState: [string, React.Dispatch<React.SetStateAction<string>>];
    itemDescriptionState: [string, React.Dispatch<React.SetStateAction<string>>];
    itemQueryState: [string, React.Dispatch<React.SetStateAction<string>>];
    isEditing?: boolean;
    resetFields?: () => void;
    reportItem?: ReportItem | null;

    enablePolicyFilterForm?: boolean; //Enable or disable filter form
    itemTypeOptions: any;
}

export default function DerReportItemForm({ metricTypeState, itemNameState, itemDescriptionState, itemQueryState, clearFields, createReportItem, disabled, ...props }: DerReportProps) {
    const [selectionOption, setMetricTypeOption] = metricTypeState;
    const [itemName, setItemName] = itemNameState;
    const [itemDescription, setItemDescription] = itemDescriptionState;
    const [itemQuery, setItemQuery] = itemQueryState;

    let der_config = props.reportItem?.report_item_config?.["der_config"];

    const [useSqlQuery, setUseSqlQuery] = useState(der_config?.["use_filter_form"] == false);

    const [dataPartition, setDataPartition] = useState<SelectProps.Option>(PolicyDataPartitions["iad"]);

    const [derOutputType, setDerOutputType] = useState<SelectProps.Option>(PolicyDEROutputTypes[der_config?.["output_type"] ?? "counts"]);

    const [useEffectFilter, setUseEffectFilter] = useState(der_config?.["effect"] != undefined);
    const [effect, setEffect] = useState<SelectProps.Option>(PolicyDEREffectTypes[der_config?.["effect"] ?? "allow"]);

    const [useActionFilter, setUseActionFilter] = useState(der_config?.["action"] != undefined);
    const [actionFilter, setActionFilter] = useState<string>(der_config?.["action"] ?? "");
    const [actionError, setActionError] = useState("");

    const [useResourceFilter, setUseResourceFilter] = useState(der_config?.["resource"] != undefined);
    const [resourceFilter, setResourceFilter] = useState<string>(der_config?.["resource"] ?? "");
    const [resourceError, setResourceError] = useState("");

    const [useConditionFilter, setUseConditionFilter] = useState(der_config?.["condition"] != undefined);
    const [conditionFilter, setConditionFilter] = useState<string>(der_config?.["condition"] ?? "");
    const [conditionError, setConditionError] = useState("");

    const [useAccountFilter, setUseAccountFilter] = useState(der_config?.["account"] != undefined);
    const [accountFilter, setAccountFilter] = useState<string>(der_config?.["account"] ?? "");
    const [accountError, setAccountError] = useState("");

    function getSelectedOption(): SelectProps.Option {
        if (props?.reportItem?.report_item_config?.["der_config"]?.["der_type"]) {
                return props.itemTypeOptions[props.reportItem.report_item_config["der_config"]["der_type"]]
            }
        return selectionOption;
    }

    function clearValidationErrors() {
        setActionError("");
        setResourceError("");
        setConditionError("");
        setAccountError("");
    }

    function validatePolicyForm(): boolean {
        let validationPass = true;
        clearValidationErrors();

        if (useActionFilter && actionFilter == "") {
            setActionError(EmptyFilterError);
            validationPass = false;
        }

        if (useResourceFilter && resourceFilter == "") {
            setResourceError(EmptyFilterError);
            validationPass = false;
        }

        if (useConditionFilter && conditionFilter == "") {
            setConditionError(EmptyFilterError);
            validationPass = false;
        }

        if (useAccountFilter && accountFilter == "") {
            setAccountError(EmptyFilterError);
            validationPass = false;
        }

        return validationPass;
    }

    function createDerReportItem() {
        if (validatePolicyForm()) {
            createReportItem(buildReportItemConfig());
        }
    }

    function buildReportItemConfig(): any {
        der_config = {
            use_filter_form: !useSqlQuery,
            output_type: derOutputType.value,
            der_type: props.isEditing ? getSelectedOption().value : selectionOption.value // Always set der_type from config when editing
        }

        if (useEffectFilter) {
            der_config["effect"] = effect.value;
        }

        if (useActionFilter) {
            der_config["action"] = actionFilter;
        }

        if (useResourceFilter) {
            der_config["resource"] = resourceFilter;
        }

        if (useConditionFilter) {
            der_config["condition"] = conditionFilter;
        }

        if (useAccountFilter) {
            der_config["account"] = accountFilter;
        }

        return { "der_config": der_config };
    }

    return (
        <Form actions={
            <SpaceBetween direction="horizontal" size="xs">
                <Button id="cancel" variant="normal" onClick={clearFields} disabled={disabled}>
                    Cancel
                </Button>
                {
                    props.isEditing === true
                    && <Button id="reset" variant="normal" onClick={props.resetFields ?? (() => { })} disabled={disabled}>
                        Reset
                    </Button>
                }
                <Button id="submit" variant="primary" onClick={() => createDerReportItem()} disabled={disabled}>
                    Submit
                </Button>
            </SpaceBetween>
        }>
            <FormField label="Type">
                <Select
                    disabled={props.isEditing && IsDerReportItemType(props.reportItem! ?? selectionOption.value)}
                    controlId="metrictype"
                    selectedOption={getSelectedOption()}
                    placeholder="Select item type"
                    options={Object.keys(props.itemTypeOptions).map((itemType) => { return props.itemTypeOptions[itemType] })}
                    onChange={event => {
                        setMetricTypeOption(event);
                    }}
                />
            </FormField>
            <FormField
                id="name"
                label="Item Name" description="Name of item">
                <Input
                    value={itemName}
                    onChange={event =>
                        setItemName(event.detail.value)
                    }
                />
            </FormField>
            <FormField
                id="description"
                label={
                    <span>
                        Item Description <i> - optional </i>{" "}
                    </span>
                }
            >
                <Textarea
                    value={itemDescription}
                    rows={3}
                    onChange={({ detail }) =>
                        setItemDescription(detail.value)}
                />
            </FormField>
            <FormField id="policy-scan-filter">
                <Toggle checked={useSqlQuery} onChange={({ detail }) => { setUseSqlQuery(detail.checked) }}>
                    Use SQL Query
                </Toggle>
            </FormField>
            {
                useSqlQuery
                    ? <FormField
                        id="query"
                        label={
                            <span>
                                Policy Scan SQL Query
                            </span>
                        }
                    >
                        <Textarea
                            value={itemQuery}
                            rows={15}
                            onChange={({ detail }) =>
                                setItemQuery(detail.value)}
                            placeholder={"SELECT COUNT(distinct p.id) FROM iam_policy.policy_data p, p.document.statement s WHERE (LOWER(s.effect) = LOWER('allow'));"}
                        />
                    </FormField>
                    : <React.Fragment>
                        <FormField id="partition" label="Partition">
                            <Select selectedOption={dataPartition} disabled={true} // TODO: Remove disable after adding suport for different partitions
                                    onChange={({detail}) => setDataPartition(detail.selectedOption)}
                                    options={Object.keys(PolicyDataPartitions).map((key) => PolicyDataPartitions[key])}/>
                        </FormField>
                        <FormField id="output-type" label="Output Columns">
                            <Select selectedOption={derOutputType}
                                onChange={({ detail }) => setDerOutputType(detail.selectedOption)}
                                options={Object.keys(PolicyDEROutputTypes).map((key) => PolicyDEROutputTypes[key])}/>
                        </FormField>
                        <FormField id="use-effect-filter">
                            <Checkbox checked={useEffectFilter} onChange={({ detail }) => { setUseEffectFilter(detail.checked) }}
                                description={
                                    <React.Fragment>
                                        Filter based on <a target="_blank" href="https://docs.aws.amazon.com/IAM/latest/UserGuide/reference_policies_elements_effect.html">policy effects</a>
                                    </React.Fragment>
                                }>
                                Effect Filter
                            </Checkbox>
                        </FormField>
                        {useEffectFilter && <FormField id="effect-filter">
                            <Select disabled={!useEffectFilter}
                                selectedOption={effect}
                                onChange={({ detail }) => { setEffect(detail.selectedOption) }}
                                options={Object.keys(PolicyDEREffectTypes).map((key) => PolicyDEREffectTypes[key])}
                                selectedAriaLabel="Selected" />
                        </FormField>}
                        <FormField id="use-action-filter">
                            <Checkbox checked={useActionFilter} onChange={({ detail }) => { setUseActionFilter(detail.checked) }}
                                description={
                                    <React.Fragment>
                                        Filter based on <a target="_blank" href="https://docs.aws.amazon.com/IAM/latest/UserGuide/reference_policies_elements_action.html">policy actions</a>
                                    </React.Fragment>
                                }>
                                Action Filter
                            </Checkbox>
                        </FormField>
                        {useActionFilter && <FormField id="action-filter" constraintText="(Separated by new line, use % as wildcard)" errorText={actionError}>
                            <Textarea disabled={!useActionFilter} value={actionFilter}
                                onChange={({ detail }) => setActionFilter(detail.value)} placeholder={"s3:*\ns3:Get*\n..."} />
                        </FormField>}
                        <FormField id="use-resource-filter">
                            <Checkbox checked={useResourceFilter} onChange={({ detail }) => { setUseResourceFilter(detail.checked) }}
                                description={
                                    <React.Fragment>
                                        Filter based on <a target="_blank" href="https://docs.aws.amazon.com/IAM/latest/UserGuide/reference_policies_elements_resource.html">policy resources</a>
                                    </React.Fragment>
                                }>
                                Resource Filter
                            </Checkbox>
                        </FormField>
                        {useResourceFilter && <FormField id="resource-filter" constraintText="(Separated by new line, use % as wildcard)" errorText={resourceError}>
                            <Textarea disabled={!useResourceFilter} value={resourceFilter}
                                onChange={({ detail }) => setResourceFilter(detail.value)} placeholder="arn.aws::*" />
                        </FormField>}
                        <FormField id="use-condition-filter">
                            <Checkbox checked={useConditionFilter} onChange={({ detail }) => { setUseConditionFilter(detail.checked) }}
                                description={
                                    <React.Fragment>
                                        Filter based on <a target="_blank" href="https://docs.aws.amazon.com/IAM/latest/UserGuide/reference_policies_elements_condition.html">policy conditions</a>
                                    </React.Fragment>
                                }>
                                Condition Filter
                            </Checkbox>
                        </FormField>
                        {useConditionFilter && <FormField id="condition-filter" constraintText="(Separated by new line, use % as wildcard)" errorText={conditionError}>
                            <Textarea disabled={!useConditionFilter} value={conditionFilter}
                                onChange={({ detail }) => setConditionFilter(detail.value)}
                                placeholder={"\"NumericLessThanEquals\": {\"aws:MultiFactorAuthAge\": \"3600\"}"} />
                        </FormField>}
                        <FormField id="use-account-filter">
                            <Checkbox checked={useAccountFilter} onChange={({ detail }) => { setUseAccountFilter(detail.checked) }}
                                description={
                                    <React.Fragment>
                                        Filter based on owning account's id
                                    </React.Fragment>
                                }>
                                Account Filter
                            </Checkbox>
                        </FormField>
                        {useAccountFilter && <FormField id="account-filter" constraintText="(Separated by new line)" errorText={accountError}>
                            <Textarea disabled={!useAccountFilter} value={accountFilter}
                                onChange={({ detail }) => setAccountFilter(detail.value)}
                                placeholder={"639982225848 --IAD(Classic) AWS managed account"} />
                        </FormField>}
                    </React.Fragment>
            }
        </Form>
    );
}