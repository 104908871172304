interface CustomStateParams {
    customSuccessMessage?: string
    customErrorMessage?: string
    customInfoMessage?: string
}


const setFlashbarCustomError: Function = customMessage => [
    { type: 'error', content: customMessage, dismissible: true }
];
const setFlashbarCustomInfo: Function = customMessage => [
    { type: 'info', content: customMessage, dismissible: true }
];
const setFlashbarCustomSuccess: Function = customMessage => [
    { type: 'success', content: customMessage, dismissible: true }
];

export { CustomStateParams, setFlashbarCustomError, setFlashbarCustomInfo, setFlashbarCustomSuccess}