import {
    SpaceBetween,
    Button,
    Grid, ButtonDropdown, Box
} from "@amzn/awsui-components-react";
import _ from "lodash";
import React, { useState } from "react";
import { ReportItem } from "../../fondue-api/generated-src";
import StatusIndicator from "@amzn/awsui-components-react/polaris/status-indicator";
import Table from "@amzn/awsui-components-react/polaris/table";
import {DateUtil} from "../../util/DateUtil";
import {getMidwayJwtToken} from "../../auth/MidwayJwtToken";
import FondueApiFactory from "../../fondue-api/FondueApiFactory";
import {Util} from "../../util/Util";


export interface RunQueryProps {

    test: string;
    reportItem?: ReportItem | null;
}

export default function RunQueryComponent({ test, reportItem, ...props}: RunQueryProps) {

    // States
    const [disableRunQueryAndClear, setDisableRunQueryAndClear] = useState(false);
    const [loadingRunQuery, setLoadingRunQuery] = useState(false);
    const lastTenWeekDropdownItems = DateUtil.dateListToItemDictionaryHyphen(DateUtil.getLastNSundayDatesFromGivenSunday(Util.dateToYMDString(DateUtil.getLastSundayDate()), 10))
    const [runQueryWBRDate, setRunQueryWBRDate] = useState(lastTenWeekDropdownItems[0]['text']);
    const [elapsedTime, setElapsedTime] = useState("0:0");
    const [disableCancelQuery, setDisableCancelQuery] = useState(true);
    const [loadingCancelQuery, setLoadingCancelQuery] = useState(false);
    const [showRunQueryStatusSuccess, setShowRunQueryStatusSuccess] = useState(false);
    const [showRunQueryStatusFail, setShowRunQueryStatusFail] = useState(false);
    const [runQueryInfoText, setRunQueryInfoText] = useState("");
    const [showResult, setShowResult] = useState(false);
    const [queryResult, setQueryResult] = useState("");

    // For functionality
    let runQueryStartDateTime = new Date(); // Needed to save query start time to calculate elapsed time
    const [runningQueryId, setRunningQueryId] = useState("");

    const querySuccessStatuses = ['FINISHED']
    const queryFailStatuses = ['FAILED', 'FORCED TIMEOUT', 'ABORTED', 'ERROR'] // 'ERROR' is a
    // custom failure response. Please refer 'poll_query()' handler in backend for more info.
    const queryDoneStatuses = [...querySuccessStatuses, ...queryFailStatuses]

    // const dummyItemID = 'item_a145f0ba-bad3-40ec-8356-e3a557314b49'
    const dummyItemID = 'item_id_NA'
    const dummyReportID = 'report_id_NA'

    // Methods
    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );

    async function pollRunQueryResult(query_id: string, reportItem: ReportItem) : Promise<boolean> {
        const FondueApi = FondueApiFactory();
        await getMidwayJwtToken();

        // Todo: Figure out way to return correct boolean from .then()=>{} or .catch()=>{}. Using this variable for now.
        let returnPollResultStatus = false;

        // Calling getReportItemQueryStatus
        await FondueApi.getReportItemQueryStatus(reportItem.id, query_id, reportItem.type, (reportItem.format) ? reportItem.format : '', reportItem.report_id)
            .then((response) => {

                // Check if query has finished running
                if(queryDoneStatuses.indexOf(response.data['status']) !== -1){
                    // Show result
                    setQueryResult(response.data['result'])
                    setShowResult(true);

                    if (querySuccessStatuses.indexOf(response.data['status']) !== -1){
                        setShowRunQueryStatusSuccess(true);
                    } else if (queryFailStatuses.indexOf(response.data['status']) !== -1){
                        setShowRunQueryStatusFail(true);
                    }
                    returnPollResultStatus = true;
                }
            })
            .catch((error)=> {
                // Show result specifically to handle 'ERROR' status message. See comment on
                // 'queryFailStatuses' const above for more info.
                const errorResponse = JSON.parse(error.request.response)

                setQueryResult(errorResponse.message.result);
                setShowResult(true);
                setShowRunQueryStatusFail(true);
                // Return 'true' to stop polling loop in 'runQuery()' method
                returnPollResultStatus = true;
            });

        return returnPollResultStatus
    }

    async function runQuery() {
        console.log('Clicked runQuery');
        console.log(reportItem);
        if (reportItem){
            // Reset statuses and show 'in-progress' components
            setShowRunQueryStatusSuccess(false);
            setShowRunQueryStatusFail(false);
            setDisableCancelQuery(true);
            setLoadingRunQuery(true);
            setDisableRunQueryAndClear(true);
            setRunQueryInfoText('Submitting query to IAM DW');

            // Initiate runQuery feature time - to be able to show elapsed time
            runQueryStartDateTime = new Date();

            // Update time every 1 sec (to show elapsed time)
            const runQueryElapsedTimeInterval = setInterval(() => {
                setElapsedTime(DateUtil.getElapsedTime(runQueryStartDateTime, new Date()));
            }, 1000);

            await getMidwayJwtToken();
            const FondueApi = FondueApiFactory();

            let finalReportItem = _.cloneDeep(reportItem)

            // Update the '.query' to latest text present in text editor
            finalReportItem.query = Util.replaceAll('IAM_WBR_DT', runQueryWBRDate, reportItem.query);
            console.log(runQueryWBRDate)
            console.log(finalReportItem.query)

            if (reportItem.id === ''){ // User is running query from 'Add Item' component and does not have item ID
                finalReportItem.id = dummyItemID;
                console.log('Did not found item ID. So swapped in ' + dummyItemID);
            }

            console.log(finalReportItem);
            // Calling runQuery
            await FondueApi.runReportItemQuery(finalReportItem.id, finalReportItem)
                .then(async (response) => {
                    // Submitting query to Redshift was success
                    // Now start polling result
                    setRunQueryInfoText('Waiting for result');
                    var gotRunQueryPollResult: boolean = false;
                    setRunningQueryId(response.data['query_id']);
                    setDisableCancelQuery(false);

                    while (!gotRunQueryPollResult) {
                        gotRunQueryPollResult = await pollRunQueryResult(response.data['query_id'], finalReportItem);

                        if (!gotRunQueryPollResult){
                            // Wait for 5 sec before retrying
                            await delay(5000);
                        }
                    }
                    console.log(response);
                })
                .catch((e)=> {
                    console.log(e.response);
                    setShowRunQueryStatusFail(true);
                });

            setRunQueryInfoText('');
            setDisableRunQueryAndClear(false);
            clearInterval(runQueryElapsedTimeInterval);
            setLoadingRunQuery(false);
        }
    }

    async function cancelRunQuery() {

        console.log('Clicked cancel run query');
        setLoadingRunQuery(false);
        setDisableCancelQuery(true);
        setLoadingCancelQuery(true);
        setRunQueryInfoText('Cancelling query');

        const FondueApi = FondueApiFactory();
        await getMidwayJwtToken();

        // Calling cancelRunQuery
        await FondueApi.cancelReportItemQuery((reportItem)?reportItem.id:'item_id_NA', runningQueryId, (reportItem)?reportItem.report_id:'report_id_NA')
            .then((response) => {
                if(response.data['status']){
                    setRunQueryInfoText('Successfully cancelled running query. Now waiting for result');
                }
            })
            .catch((e)=> {
                setRunQueryInfoText('Failed to cancel running query');
            });

        setLoadingCancelQuery(false);

    }

    async function clearRunQueryStatus() {
        console.log('Clicked clear Run Query');
        setElapsedTime('0:0');
        setShowRunQueryStatusSuccess(false);
        setShowRunQueryStatusFail(false);
        setQueryResult('')
        setShowResult(false);
        setDisableCancelQuery(true);
        setRunQueryInfoText('');
    }


    // TODO Kapil - debug the warning of 'key' in element
    return (
        <SpaceBetween size="m">
            <Grid
                id='runQueryGrid'
                disableGutters
                gridDefinition={[
                    { colspan: 10 },
                    { colspan: 2 }
                ]}
            >
                <SpaceBetween id="runQueryElements" direction="horizontal" size="m">
                    <Button id="runquery" variant="normal" iconName="caret-right-filled"
                            onClick={runQuery} disabled={disableRunQueryAndClear} loading={loadingRunQuery}>
                        Run Query
                    </Button>

                    <ButtonDropdown
                        id="runQueryWBRDropdown"
                        ariaLabel={runQueryWBRDate}
                        items={lastTenWeekDropdownItems}
                        disabled={disableRunQueryAndClear}
                        onItemClick={(event) => setRunQueryWBRDate(event.detail.id)}
                    >
                        {runQueryWBRDate}
                    </ButtonDropdown>

                    <SpaceBetween direction="horizontal" size="m">
                        <Box variant="p" padding={{left: "s", right: "s"}}>{elapsedTime}</Box>
                        <Button id="cancelRunQuery" variant="normal" onClick={cancelRunQuery}
                                disabled={disableCancelQuery} loading={loadingCancelQuery}>
                            Cancel
                        </Button>
                    </SpaceBetween>

                    {showRunQueryStatusSuccess ? <StatusIndicator>{elapsedTime} - Complete for {runQueryWBRDate}</StatusIndicator> : ''}
                    {showRunQueryStatusFail ? <StatusIndicator type="error">{elapsedTime} - Failed for {runQueryWBRDate}</StatusIndicator> : ''}

                </SpaceBetween>

                <Box id="clear-box" float="right">
                    <Button id="clear-run-query" variant="link" disabled={disableRunQueryAndClear}
                            onClick={clearRunQueryStatus}>Clear</Button>
                </Box>

            </Grid>

            <p><i>{runQueryInfoText}</i></p>

            {
                showResult &&
                (<Table
                    id="run-query-result"
                    columnDefinitions={[
                        {
                            id: "result",
                            header: "Result",
                            cell: item => item.result || "-"
                        }
                    ]}
                    items={[
                        {
                            result: queryResult
                        }
                    ]}
                />)
            }
        </SpaceBetween>
    );
}

