import { useState, useEffect } from 'react';
import { useHistory, useParams } from "react-router-dom";
import Form from "@amzn/awsui-components-react/polaris/form";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import Flashbar, {FlashbarProps} from "@amzn/awsui-components-react/polaris/flashbar";
import Input from '@amzn/awsui-components-react/polaris/input';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Header from '@amzn/awsui-components-react/polaris/header';
import Button from '@amzn/awsui-components-react/polaris/button';
import Container from '@amzn/awsui-components-react/polaris/container';
import Link from '@amzn/awsui-components-react/polaris/link';
import RadioGroup from "@amzn/awsui-components-react/polaris/radio-group";
import Select from "@amzn/awsui-components-react/polaris/select";

import FondueApiFactory from '../../fondue-api/FondueApiFactory';
import { Report, FondueApi } from '../../fondue-api/generated-src';
import { derReportType, wbrReportType } from '../../common/ReportTypes'
import {getMidwayJwtToken} from "../../auth/MidwayJwtToken";
import axios from "axios";
import {getAppSetting, initializeAppSettings} from "../../config/AppSettings";

const defaultBindle = "amzn1.bindle.resource.iptmumwaqe4boezjseca"
const derDefaultBindle = "amzn1.bindle.resource.mj25plcpikttmozfb3xq"

const flashbarInvalidInput: FlashbarProps.MessageDefinition[] = [
    { type: 'error', content: 'Invalid Input: Name and Owner are required fields', dismissible: true }
];

const flashbarSubmittingInput: FlashbarProps.MessageDefinition[] = [
    { type: 'info', content: 'Creating Report', dismissible: true }
];

export default function ReportsFormPage({setFlashbar}) {
    Object.freeze(Object.prototype);
    async function createReport() {
        await getMidwayJwtToken();
        // If name or owner field not present, set InvalidInput Flashbar
        if(!reportName || !reportOwner || !reportBindle) {
            setFlashbar(flashbarInvalidInput)
            return;
        }

        const FondueApi = FondueApiFactory();

        const report: Report = {
            id: '',
            name: reportName,
            owner: reportOwner,
            state: 'DISABLED',
            category: reportCategory,
            project: reportProject,
            report_item_order: [],
            bindle: reportBindle,
            report_type: reportType
        };
        setFlashbar(flashbarSubmittingInput)
        setCreateButtonDisabled(true);

        await FondueApi.createReport(report);

        // Redirect to Reports Page after submitting
        history.push('/reports')

    }


    const [reportName, setReportName] = useState("")
    const [reportOwner, setReportOwner] = useState("")
    const [reportCategory, setReportCategory] = useState("")
    const [reportProject, setReportProject] = useState("")
    const [reportBindle, setReportBindle] = useState(defaultBindle)
    const [reportType, setReportType] = useState(wbrReportType.value)
    const [createButtonDisabled, setCreateButtonDisabled] = useState(false);

    const [useDefaultBindle, setUseDefaultBindle] = useState(true);
    const [bindleOption, setBindleOption] = useState("default")
    const [isUserDerAuthorized, setisUserDerAuthorized] = useState(false);
    const [selectedReportOption, setSelectedReportOption] = useState(wbrReportType.selectOption);
    const history = useHistory();

    async function validateUserDerAuthorization(FondueApi: FondueApi) {
        await getMidwayJwtToken();
        await FondueApi.validateDerAuthorization()
            .then((response) => {
                if (response.data.authorized){
                    setisUserDerAuthorized(true);
                }
            });
    }

    function setSelectedOptionFields(detail){
        setSelectedReportOption(detail.selectedOption)
        switch (detail.selectedOption.value) {
            case "wbr":
                setReportType(wbrReportType.value);
                if (useDefaultBindle) setReportBindle(defaultBindle) 
                break;
            case "der":
                setReportType(derReportType.value);
                // TODO: Should remove default bindle for DER reports once open to outside of `iam-core-engine-analytics`
                // Further discussion is required to determine if we want to enable bindles at all or restrict only to the user who created
                if (useDefaultBindle) setReportBindle(derDefaultBindle);
                break;
        }
    }

    function setBindleOptionFields(detail){
        setBindleOption(detail.value)
        switch (detail.value) {
            case "default":
                setUseDefaultBindle(true);
                reportType === derReportType.value ? setReportBindle(derDefaultBindle) : setReportBindle(defaultBindle);
                break;
            case "custom":
                setUseDefaultBindle(false);
                setReportBindle("");
                break;
        }
    }

    useEffect(() => {
        (async () => {
            const FondueApi = FondueApiFactory();
            await validateUserDerAuthorization(FondueApi)
        })();
    }, [FondueApiFactory]);

    return (
        <Container
            header={
                <Header variant="h2" description="Create Fondue Report">
                    Create Report
                </Header>
            }
        >
            <Form
                actions={
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button id="cancel" variant="link" href={`#/reports`}>
                            Cancel
                        </Button>
                        <Button id="submit" variant="primary" onClick={createReport} disabled={createButtonDisabled}>
                            Submit
                        </Button>
                    </SpaceBetween>
                }
            >
                <FormField id="type" label="Report Type">
                    <Select
                        selectedOption={selectedReportOption}
                        onChange={ ({ detail }) => setSelectedOptionFields(detail) }
                        options={[wbrReportType.selectOption, {...derReportType.selectOption, disabled: !isUserDerAuthorized}]}
                    />
                </FormField>
                <FormField id="name" description="Name of Report." label="Report Name">
                    <Input
                        value = {reportName}
                        onChange={event =>
                            setReportName(event.detail.value)}
                    />
                </FormField>
                <FormField id="owner" description="Business Owner of Report." label="Report Owner">
                    <Input
                        value={reportOwner}
                        onChange={event =>
                            setReportOwner(event.detail.value)
                        }
                    />
                </FormField>
                <FormField
                    id="category"
                    description="Category of Report."
                    label={
                        <span>
                          Report Category <i> - optional</i>{" "}
                        </span>
                    }
                >
                    <Input
                        value={reportCategory}
                        onChange={event =>
                            setReportCategory(event.detail.value)
                        }
                    />
                </FormField>
                <FormField
                    id="project"
                    description="Project type of Report."
                    label={
                        <span>
                          Report Project <i> - optional</i>{" "}
                        </span>
                    }
                >
                    <Input
                        value={reportProject}
                        onChange={event =>
                            setReportProject(event.detail.value)
                        }
                    />
                </FormField>
                <FormField 
                    id="bindle" 
                    description={
                        <div>
                            To access this report, users must be a member of this bindle.{' '}                           
                            <Link
                              href="https://w.amazon.com/bin/view/BusinessMetrics/Fondue/Bindles"
                              target="_blank"
                            >
                                Learn more
                            </Link>
                        </div>
                    }
                    label="Permission Bindle"
                >
                    <RadioGroup
                        onChange={({ detail }) => setBindleOptionFields(detail)}
                        value={bindleOption}
                        items={[
                            {
                            value: "default",
                            label:
                                <div>
                                    Default bindle for this report type - {' '}
                                    {reportType === wbrReportType.value ?
                                    <Link
                                        href="https://bindles.amazon.com/software_app/BizmetFondueReports"
                                        target="_blank"
                                    >
                                        Business Metrics owned Bindle
                                    </Link> :
                                    <Link
                                        href="https://bindles-gamma.amazon.com/software_app/FondueDER"
                                        target="_blank"
                                    >
                                        IAM Core Engine Analytics owned Bindle
                                    </Link> 
                                    }
                                </div>
                            ,
                            description: "This report will be available to a wider set of contributors defined in the default bindle."
                            },
                            {
                            value: "custom",
                            label: "Custom bindle",
                            description: "Use a different bindle to control permissions to your report."
                            }
                        ]}  
                    />
                    <Input
                        value = {reportBindle}
                        placeholder = {reportBindle}
                        onChange={event =>
                            setReportBindle(event.detail.value)
                        }
                        disabled={useDefaultBindle}
                    />
                </FormField>
            </Form>
        </Container>
    );
}
