import React, { Component, useEffect, useState } from 'react';
import AppLayout from '@amzn/awsui-components-react/polaris/app-layout';
import Flashbar, { FlashbarProps } from '@amzn/awsui-components-react/polaris/flashbar';
import { Breadcrumbs, ServiceNavigation } from '../navigation';
import { appLayoutLabels } from '../../common/labels';
import {
    Box,
    Container,
    Grid,
    ColumnLayout,
    Header,
    Table,
    SpaceBetween,
    RadioGroup,
    Spinner
} from '@amzn/awsui-components-react/polaris';
import Button from "@amzn/awsui-components-react/polaris/button";
import ButtonDropdown from "@amzn/awsui-components-react/polaris/button-dropdown";
import { useHistory } from 'react-router-dom';
import { useParams } from "react-router-dom";
import DatePicker from "@amzn/awsui-components-react/polaris/date-picker";
import Form from "@amzn/awsui-components-react/polaris/form";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import FondueApiFactory from '../../fondue-api/FondueApiFactory';
import { Report, ReportItem, ReportSchedule, ReportExecution, RetrieveReportRequest,
    ReportExecutionsRequest, RetrieveCFUrlRequest, GenerateReportRequest, FondueApi, GenerateDerReportRequest, RetrieveDatadumpRequest } from '../../fondue-api/generated-src';
import {States} from "../../common/States";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import Input from "@amzn/awsui-components-react/polaris/input";
import Alert from "@amzn/awsui-components-react/polaris/alert";
import { getMidwayJwtToken } from "../../auth/MidwayJwtToken";
import { IsDerReportTypeString } from "../../common/ReportTypes";
import {DateUtil} from "../../util/DateUtil"
import {Util} from "../../util/Util";
import TextFilter from "@amzn/awsui-components-react/polaris/text-filter";
import Pagination from "@amzn/awsui-components-react/polaris/pagination";
import { useCollection } from '@amzn/awsui-collection-hooks';
import CollectionPreferences, {CollectionPreferencesProps} from "@amzn/awsui-components-react/polaris/collection-preferences";
import StatusIndicator from "@amzn/awsui-components-react/polaris/status-indicator";
import {getMatchesCountText, paginationLabels} from "../reports/table-config";


export interface ReportResultDateSelectionProps {
    reportName: string;
    reportType?: string;
    setState: (state: States) => void;
    setFlashbar: (x: FlashbarProps.MessageDefinition[]) => void;
}

export default function ReportResultDateSelection({reportName, reportType, setState, setFlashbar}: ReportResultDateSelectionProps) {
    Object.freeze(Object.prototype);
    const { report_id }= useParams<{report_id: string}>();
    const [startDateValue, setStartDateValue] = useState("");
    const [endDateValue, setEndDateValue] = useState("");

    const [fileName, setFileName] = useState("");

    const [downloadDisabled, setDownloadDisabled] = useState(true);
    const [genReportDisabled, setGenReportDisabled] = useState(true);
    const [downloaddatadumpDisabled, setDownloadDatadumpDisabled] = useState(true);
    const [retrievePdfDisabled, setRetrievePdfDisabled] = useState(true);
    const [loadPdf, setLoadPdf] = useState(false);

    const [startDateDisabled, setStartDateDisabled] = useState(true);
    const [endDateDisabled, setEndDateDisabled] = useState(false);
    const [viewResultDisabled, setViewResultDisabled] = useState(true);
    const [viewResultLoading, setViewResultLoading] = useState(false);
    const [cancelDisabled, setCancelDisabled] = useState(true);
    const [runAgainDisabled, setRunAgainDisabled] = useState(true);
    const [downloadDisplayedDisabled, setDownloadaDisplayedDisabled] = useState(true);
    const [showLatestReportError, setShowLatestReportError] = useState(false);
    const [refreshTable, setRefreshTable] = useState(false);

    const [cloudFrontUrl, setCloudFrontUrl] = useState("");
    const [pdfUrl, setPdfUrl] = useState("");

    const [runReportModalVisible, setRunReportModalVisible] = useState(false);
    const [runReportOption, setRunReportOption] = useState("lastWeek");
    const [reportExecutionDate, setReportExecutionDate] = useState(Util.dateToYMDString(DateUtil.getLastSundayDate()));

    const [cancelModalVisible, setCancelModalVisible] = useState(false);
    const [runAgainModalVisible, setRunAgainModalVisible] = useState(false);

    const [previousWeeksToRunDropdownLabel, setPreviousWeeksToRunDropdownLabel] = useState("")
    const [previousWeeksToRunDropdownDate, setPreviousWeeksToRunDropdownDate] = useState("")
    const [customDateRangeComponentsDisabled, setCustomDateRangeComponentsDisabled] = useState(true)
    const [runReportModalButtonLoading, setRunReportModalButtonLoading] = useState(false)

    // Alert used on page load if most recent execution is failed
    const [showErrorAlert, setShowErrorAlert] = useState(false)
    const numWeeksInDropdown = 53

    var lastNWeekDropdownItems = DateUtil.dateListToNumberedItemDictionary(DateUtil.getLastNSundayDatesFromGivenSunday(reportExecutionDate, numWeeksInDropdown))



    const [allExecutions, setAllExecutions] = useState<ReportExecution[]>();
    const [selectedItems, setSelectedItems] = useState<ReportExecution[]>([]);
    const [latestExecution, setLatestExecution] = useState<ReportExecution>();
    const [selectedExecution, setSelectedExecution] = useState<ReportExecution>();
    const [displayedExecutionID, setDisplayedExecutionID] = useState("");
    const [showResultContainer, setShowResultContainer] = useState(false);

    // TODO: Use CustomStateParams when merging into mainline
    const setFlashbarCustomSuccess: Function = customMessage => [
        {
            type: 'success', content: customMessage, dismissible: true,
            onDismiss: () => setFlashbar([])
        }
    ];



    const flashbarFileNotFound: FlashbarProps.MessageDefinition[] = [
        {
            type: 'error', content: 'File not found for report and date range', dismissible: true,
            onDismiss: () => setFlashbar([])
        }
    ];

    const flashbarReportRetrievingError: FlashbarProps.MessageDefinition[] = [
        {
            type: 'error', content: 'Error retrieving report result', dismissible: true,
            onDismiss: () => setFlashbar([])
        }
    ];


    const flashbarReportAccessDenied: FlashbarProps.MessageDefinition[] = [
        {
            type: 'error', content: 'User does not have permission to view this Report Result', dismissible: true,
            onDismiss: () => setFlashbar([])
        }
    ];


    const flashbarGeneratingDerReport: FlashbarProps.MessageDefinition[] = [
        {
            type: 'info', content: 'Manually generating a report', dismissible: true,
            onDismiss: () => setFlashbar([])
        }
    ]

    const flashbarGeneratingDerReportError: FlashbarProps.MessageDefinition[] = [
        {
            type: 'info', content: 'Error manually generating DER report', dismissible: true,
            onDismiss: () => setFlashbar([])
        }
    ];

    const flashbarRunReportSuccess: FlashbarProps.MessageDefinition[] = [
        {
            type: 'success', content: 'Successfully scheduled report generation', dismissible: true,
            onDismiss: () => setFlashbar([])
        }
    ];


    function clearFields(){
        setStartDateValue("");
        setEndDateValue("");
    }

    function downloadReportS3(url: string){
        const link = document.createElement('a');

        link.href = url;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
	
	async function retrieveDatadump(FondueApi: FondueApi, retrieveDatadumpRequest: RetrieveDatadumpRequest) {
		await getMidwayJwtToken();
		await FondueApi.retrieveDatadumpResult(report_id, retrieveDatadumpRequest)
			.then((response) =>{
				if(cloudFrontUrl != '') {
					downloadReportS3(response.data.url!);
					setState(States.success);
				}
				else{
					setFlashbar(flashbarReportAccessDenied);
				}
			})
			.catch((e) => {
				// Unaothorized error
				if (e.response.status === 403){
					setFlashbar(flashbarReportAccessDenied);
				}
			})
	}

    async function retrieveReportExcel(FondueApi: FondueApi, retrieveReportRequest: RetrieveReportRequest) {
        await getMidwayJwtToken();
        await FondueApi.retrieveReportResult(report_id, retrieveReportRequest)
            .then((response) =>{
                // Trigger download of Excel in Browser if Cloudfront Signed Url present
                if(cloudFrontUrl != '') {
                    downloadReportS3(response.data.url!);

                    setState(States.success);
                }
                // If CloudFrontUrl is empty show access denied
                else{
                    setFlashbar(flashbarReportAccessDenied);
                }

            })
            .catch((e) => {
                // Unauthorized error
                if(e.response.status === 403){
                    setFlashbar(flashbarReportAccessDenied);
                }
                // File not found error
                else if(e.response.status === 404){
                    setFlashbar(flashbarFileNotFound);
                }
                else{
                    setFlashbar(flashbarReportRetrievingError);
                }

                setState(States.error);
            })
    }

    function enqueueReportExecutionLastWeekOnly(){
        const FondueApi = FondueApiFactory();
        const reportExecutionsRequest: ReportExecutionsRequest = {
            wbr_date_range: [Util.dateToYMDString(DateUtil.getLastSundayDate())]
        }
        enqueueReportExecution(FondueApi, reportExecutionsRequest);
    }

    function enqueueReportExecutionLast10Weeks(){
        const FondueApi = FondueApiFactory();
        const reportExecutionsRequest: ReportExecutionsRequest = {
            wbr_date_range: DateUtil.getLast10SundayDates()
        }
        enqueueReportExecution(FondueApi, reportExecutionsRequest);
    }

    function enqueueReportExecutionCustomDateRange(){
        const FondueApi = FondueApiFactory();
        let startDate = DateUtil.convertSlashDateToHyphenDate(previousWeeksToRunDropdownDate)
        let endDate = reportExecutionDate

        const reportExecutionsRequest: ReportExecutionsRequest = {
            wbr_date_range: DateUtil.getSundayDateRange(startDate, endDate)
        }
        enqueueReportExecution(FondueApi, reportExecutionsRequest);
    }

    function getLast10WeeksWBRRangeText(){
        let wbr_range = DateUtil.getLast10SundayDates()
        return DateUtil.convertHyphenDateToSlashDate(wbr_range[9]) + ' to ' + DateUtil.convertHyphenDateToSlashDate(wbr_range[0])
    }

    async function enqueueReportExecution(FondueApi: FondueApi, reportExecutionsRequest: ReportExecutionsRequest) {

        setRunReportModalButtonLoading(true)
        await getMidwayJwtToken();

        await FondueApi.enqueueReportExecution(report_id, reportExecutionsRequest)
            .then((response) => {
                closeRunReportModal()
                setRunReportModalButtonLoading(false)
                var wbr_date_range = reportExecutionsRequest.wbr_date_range;
                var message = `Successfully initiated report to run for ${wbr_date_range[0]}`
                if(reportExecutionsRequest.wbr_date_range.length > 1){
                    message += ` to ${wbr_date_range[wbr_date_range.length - 1]}`
                }
                setFlashbar(setFlashbarCustomSuccess(message));

                if(runAgainModalVisible){
                    closeRunAgainModal();
                }
            })
            .catch((e) => {
                // Todo: return appropriate error message to user about which WBR date runs conflict this request
                // Same 'todo' has been added in backend API function as well
                setShowErrorAlert(true)
                setRunReportModalButtonLoading(false)
            })
    }

    function retrieveReportExecutions(){
        const FondueApi = FondueApiFactory();
        setRefreshTable(true);
        listReportExecutions(FondueApi);

        // Disabling all action buttons as selected Execution will be cleared
        setRunAgainDisabled(true);
        setDownloadDisabled(true);
        setCancelDisabled(true);
    }

    async function listReportExecutions(FondueApi: FondueApi) {
        await getMidwayJwtToken();

        await FondueApi.getReportExecutions(report_id)
            .then((response) => {
                setAllExecutions(response.data['results']);

                setRefreshTable(false);
            })
            .catch((e) => {
                setRefreshTable(false);
                setAllExecutions([]);
            })
    }

    function handleDateDropdownClick(dateID){
        lastNWeekDropdownItems.forEach( (element) => {
            if (element['id'] === dateID){
                setPreviousWeeksToRunDropdownLabel(element['text'])
                setPreviousWeeksToRunDropdownDate(dateID)
            }
        });
    }

    function submitRunReportRequest() {
        switch (runReportOption) {
            case 'lastWeek':
                enqueueReportExecutionLastWeekOnly();
                break;
            case 'last10Weeks':
                enqueueReportExecutionLast10Weeks();
                break;
            case 'customDateRange':
                enqueueReportExecutionCustomDateRange();
                break;
        }
    }

    function runReportModalOptionClick(value){
        setRunReportOption(value)

        switch (value) {
            case 'lastWeek':
                setCustomDateRangeComponentsDisabled(true);
                break;
            case 'last10Weeks':
                setCustomDateRangeComponentsDisabled(true);
                break;
            case 'customDateRange':
                setCustomDateRangeComponentsDisabled(false);
                break;
        }
    }

    function handleDatePickerClick(selectedDate){
        // Here, selectedDate is in YYYY-MM-DD format
        setReportExecutionDate(selectedDate)
        lastNWeekDropdownItems = DateUtil.dateListToNumberedItemDictionary(DateUtil.getLastNSundayDatesFromGivenSunday(selectedDate, numWeeksInDropdown))
        handleDateDropdownClick(DateUtil.convertHyphenDateToSlashDate(selectedDate))

    }

    function closeRunReportModal(){
        // Close Modal
        setRunReportModalVisible(false)

        // Hide all messages
        setShowErrorAlert(false)
    }

    function cancelExecution(){
        const FondueApi = FondueApiFactory();

        const cancelRequest: ReportExecution = {
            report_id: report_id,
            wbr_date: selectedExecution!.wbr_date,
            execution_id: selectedExecution!.execution_id
        }

        cancelReportExecution(FondueApi, cancelRequest);
    }

	function downloadDatadump(){
		const FondueApi = FondueApiFactory();
		const retrieveDatadumpRequest: RetrieveDatadumpRequest = {
			report_id: report_id,
			reportName: reportName,
			date: endDateValue
		}
		retrieveDatadump(FondueApi, retrieveDatadumpRequest);
	}

    async function cancelReportExecution(FondueApi: FondueApi, cancelRequest:ReportExecution) {

        setRunReportModalButtonLoading(true)
        await getMidwayJwtToken();

        await FondueApi.cancelReportExecution(report_id, cancelRequest)
            .then((response) => {
                closeCancelModal();
                setRunReportModalButtonLoading(false)
            })
            .catch((e) => {
                setRunReportModalButtonLoading(false)
            })
    }

    function generateReport(){
        const FondueApi = FondueApiFactory();

        const generateDerReportRequest: GenerateDerReportRequest = {
            report_id: report_id,
            current_date: endDateValue,
        }
        manuallyGenerateReportSchedule(FondueApi, generateDerReportRequest);
    }

    async function manuallyGenerateReportSchedule(FondueApi: FondueApi, generateDerReportRequest: GenerateDerReportRequest) {
        await getMidwayJwtToken();
        await FondueApi.generateDerReport(report_id, generateDerReportRequest)
            .then((response) => {
                setFlashbar(flashbarGeneratingDerReport);
            })
            .catch((e) => {
                setFlashbar(flashbarGeneratingDerReportError);
                setState(States.error);
            })
    }

    function closeRunAgainModal(){
        // Close Modal
        setRunAgainModalVisible(false);
        // Hide all messages
        setShowErrorAlert(false)

        // Disabling all action buttons as selected Execution will be cleared
        setRunAgainDisabled(true);
        setDownloadDisabled(true);
        setCancelDisabled(true);
    }

    function runAgain(){
        const FondueApi = FondueApiFactory();
        setRunReportModalButtonLoading(true)

        const reportExecutionsRequest: ReportExecutionsRequest = {
            wbr_date_range: [selectedExecution!.wbr_date]
        }
        enqueueReportExecution(FondueApi, reportExecutionsRequest);
    }


    function closeCancelModal(){
        // Close Modal
        setCancelModalVisible(false);
        setCancelDisabled(true);
    }

    async function retrieveCloudfrontSignedUrl(FondueApi: FondueApi, retrieveReportRequest: RetrieveReportRequest) {
        await getMidwayJwtToken();
        await FondueApi.retrieveCloudFrontSignedUrl(report_id, retrieveReportRequest)
            .then((response) =>{
                setCloudFrontUrl(response.data.url!)
                setRetrievePdfDisabled(false);
            })
            .catch((e) => {
                if(e.response.status === 403){
                    setDownloadDisabled(true);
                    setGenReportDisabled(true);
                    setDownloadDatadumpDisabled(true);
                    setRetrievePdfDisabled(true);
                }
                setState(States.error);
            })
    }


    function retrieveLatestReportResult(fileType: string, download: boolean, exec_id = ""){
        const FondueApi = FondueApiFactory();
        if(latestExecution){
            const retrieveReportRequest: RetrieveReportRequest = {
                report_id: report_id,
                reportName: reportName,
                fileName: fileName,
                startDate: startDateValue,
                endDate: latestExecution!.wbr_date,
                fileType: fileType,
                executionId: latestExecution!.execution_id
            }
            retrieveReportFile(FondueApi, retrieveReportRequest, download);
            setViewResultDisabled(true);
        }
    }

    function viewResult(){
        setViewResultLoading(true);
        retrieveReportResult('pdf', false);
    }

    function retrieveReportResult(fileType: string, download: boolean, exec_id = ""){
        const FondueApi = FondueApiFactory();

        // If no execution id passed in set exec_id in retrieve request to selected execution
        if(exec_id === ""){
            exec_id = selectedExecution!.execution_id || "";
        }

        const retrieveReportRequest: RetrieveReportRequest = {
            report_id: report_id,
            reportName: reportName,
            fileName: fileName,
            startDate: startDateValue,
            endDate: selectedExecution!.wbr_date,
            fileType: fileType,
            executionId: exec_id
        }

        retrieveReportFile(FondueApi, retrieveReportRequest, download);
        setViewResultDisabled(false)
    }

    async function retrieveReportFile(FondueApi: FondueApi, retrieveReportRequest: RetrieveReportRequest, download: boolean) {
        await getMidwayJwtToken();
        await FondueApi.retrieveReportResult(report_id, retrieveReportRequest)
            .then((response) =>{
                // If download set to True
                if(download){
                    downloadReportS3(response.data.url!);
                }
                else{
                    setPdfUrl(response.data.url!);
                    setDisplayedExecutionID(retrieveReportRequest.executionId!)
                    setDownloadaDisplayedDisabled(false);
                    setShowResultContainer(true);

                    setEndDateValue(retrieveReportRequest.endDate!)
                    setViewResultLoading(false);
                }
            })
            .catch((e) => {
                // For errors retrieving w/ download as False, replace PdfURL
                if(!download){
                    setPdfUrl("");
                    setDownloadaDisplayedDisabled(true);
                    setViewResultLoading(false);

                }
                // Unauthorized error
                if(e.response.status === 403){
                    setFlashbar(flashbarReportAccessDenied);
                }
                // File not found error
                else if(e.response.status === 404){
                    setFlashbar(flashbarFileNotFound);
                }
                else{
                    setFlashbar(flashbarReportRetrievingError);
                }
                setState(States.error);
            })
    }

    function retrieveCloudFrontSignedUrl(){
        const FondueApi = FondueApiFactory();

        const retrieveCFUrlRequest: RetrieveCFUrlRequest = {
            report_id: report_id
        }

        retrieveCloudfrontSignedUrl(FondueApi, retrieveCFUrlRequest);
    }

    function setStartDate(date){
        setStartDateValue(date)
        const startDate = new Date(date);
        const endDate = new Date(startDate.setDate(startDate.getDate() + 70));

        // Get endDate in yyyy-mm-dd format
        const endDateString = endDate.toISOString().split("T")[0];
        setEndDateValue(endDateString);
    }

    function setEndDate(date){
        setEndDateValue(date)
        const endDate = new Date(date);
        const startDate = new Date(endDate.setDate(endDate.getDate() - 70));

        // Get endDate in yyyy-mm-dd format
        const startDateString = startDate.toISOString().split("T")[0];
        setStartDateValue(startDateString);
    }

    function getPreviousSunday(date = new Date()) {
        const previousSunday = new Date();
        previousSunday.setDate(date.getDate() - date.getDay());
        return previousSunday;
    }

    async function setRecentWBRDate(){
        const date = new Date();
        const sunday = getPreviousSunday(date);

        const offset = sunday.getTimezoneOffset();
        const sundayOffsetDate = new Date(sunday.getTime() - (offset*60*1000));

        if (!IsDerReportTypeString(reportType)) {
            // Default to most recent wbr date
            setEndDate(sundayOffsetDate.toISOString().split("T")[0]);
        } else {
            // Default to 1 week for DER reports
            const todayOffset = date.getTimezoneOffset();
            const todayOffsetDate = new Date(date.getTime() - (todayOffset*60*1000));

            setStartDateValue(sundayOffsetDate.toISOString().split("T")[0]);
            setEndDateValue(todayOffsetDate.toISOString().split("T")[0]);
        }
    }

    function buttonAction(event){
        switch (event.detail.id) {
            case "dxlsx":
                retrieveReportResult('xlsx', true);
                break;
            case "dpdf":
                retrieveReportResult('pdf', true);
                break;
            case "genrep":
                generateReport();
                break;
            case "djson":
                downloadDatadump();
                break;
            case "cancel":
                setCancelModalVisible(true);
                break;
            case "runagain":
                setRunAgainModalVisible(true);
                break;
        }
    }

    const actionButtonItems = [
        { text: "Download Excel", id: "dxlsx", disabled: downloadDisabled },
        { text: "Download PDF", id: "dpdf", disabled: downloadDisabled },
        { text: "Run again", id: "runagain", disabled: runAgainDisabled },
        { text: "Cancel", id: "cancel", disabled: cancelDisabled },
    ];

    if (!downloaddatadumpDisabled) {
        actionButtonItems.push({ text: "Download Datadump", id: "djson", disabled: downloaddatadumpDisabled })
    }

    const actionButtons = (
        <ButtonDropdown
            id ="btnDropdown"
            items={
                actionButtonItems
            }
            onItemClick={(event) => buttonAction(event)}
        >
            Actions
        </ButtonDropdown>
    )


    const [pageSize, setPageSize] = useState(20);

    const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
        allExecutions || [],
        {
            filtering: {
                empty: (
                    <Box textAlign="center" color="inherit">
                        <b>No executions</b>
                        <Box
                            padding={{bottom: "s"}}
                            variant="p"
                            color="inherit"
                        >
                            No executions to display.
                        </Box>
                    </Box>
                ),
            },
            pagination: {
                pageSize: pageSize
            },
            sorting: {},
            selection: {}
        }
    );

    function handlePagination(event) {
        setSelectedItems([]);
        setSelectedExecution(undefined);
        setDownloadDisabled(true);
        setCancelDisabled(true);
        setRunAgainDisabled(true);
        setViewResultDisabled(true);
    }


    function handleSelect(detail) {
        setSelectedItems(detail.selectedItems);

        setSelectedExecution(detail.selectedItems[0]);

        let status = detail.selectedItems[0].execution_status;

        setDownloadDisabled(true);
        setCancelDisabled(true);
        setRunAgainDisabled(false);
        setViewResultDisabled(true);

        if (status == "Complete"){
            setViewResultDisabled(false);
            setDownloadDisabled(false);
        }

        if (status == "In_Progress" || status == "Pending"){
            setCancelDisabled(false);
            setRunAgainDisabled(true);
        }
    }

    function statusToIndicator(status){
        var message = status
        switch (status) {
            case 'Complete':
                status = "success"
                break;
            case 'Failed':
                status = "error"
                break;
            case 'Pending':
                status = "pending"
                break;
            case 'In_Progress':
                message = "In Progress"
                status = "in-progress"
                break;
            case 'Canceled':
                status = "stopped"
                break;
        }
        return <StatusIndicator type={status}>{message}</StatusIndicator>
    }

    useEffect(() => {
        if(reportName != ''){
            setRecentWBRDate()
            if(cloudFrontUrl === ""){
                retrieveCloudFrontSignedUrl();
            }
            if(IsDerReportTypeString(reportType)) {
                setStartDateDisabled(true);
                setEndDateDisabled(true);
                setGenReportDisabled(false);
                setDownloadDatadumpDisabled(false);
            }

            // Initialize Datepicker and
            handleDatePickerClick(reportExecutionDate)
            retrieveReportExecutions();
        }

    }, [reportName, setAllExecutions, runReportModalButtonLoading]);

    useEffect(() => {
        // Load and set latest execution for WBR Reports
        // This will load PDF by default if latest execution is complete, else show an alert
        if(allExecutions && !IsDerReportTypeString(reportType)){
            for (let i=0; i < allExecutions!.length; i++){

                var exec_status = allExecutions[i].execution_status;
                if(exec_status === 'Complete') {
                    setLatestExecution(allExecutions[i]);
                    setShowResultContainer(true);
                    break;
                }
                if(exec_status === 'Failed'){
                    setShowLatestReportError(true);
                    setShowResultContainer(false);
                    break;
                }
            }

        }

    }, [reportName, reportType, allExecutions, setAllExecutions]);

    useEffect(() => {
        if(cloudFrontUrl != ""){
            retrieveLatestReportResult('pdf', false);
            setLoadPdf(true);
        }
    },[latestExecution, setLatestExecution, cloudFrontUrl, setCloudFrontUrl])



    useEffect(() => {
    },[loadPdf, setLoadPdf]);

    return (
        <SpaceBetween direction="vertical" size="xs">
            {showLatestReportError &&
            <Alert type="error" header="Report failed">
                The most recent execution of this report failed. You can run the report again or view results for a previous execution.
            </Alert>
            }
            {showResultContainer &&
            <Container
                header={
                    <Header
                        variant="h2"
                        actions={
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button id="dwnxlsx" variant="normal" iconName="download" disabled={downloadDisplayedDisabled}
                                        onClick={() => retrieveReportResult('xlsx', true, displayedExecutionID)}>
                                    Download as XLSX
                                </Button>
                            </SpaceBetween>
                        }
                    >
                        {"Latest File | " + startDateValue + " to " + endDateValue}
                    </Header>
                }
            >
                <SpaceBetween direction="vertical" size="xl">
                    <Box variant="h2">
                        {(pdfUrl == "" && cloudFrontUrl != "") && <Spinner/> }
                        {(pdfUrl != "" && cloudFrontUrl != "" && loadPdf) &&
                        <iframe style={{border: "none", width: "100%"}} height={"600px"} src={pdfUrl}/>}
                    </Box>
                </SpaceBetween>
            </Container>}


            <SpaceBetween direction="vertical" size="xl">
            </SpaceBetween>
            <Modal
                onDismiss={closeRunReportModal}
                visible={runReportModalVisible}
                closeAriaLabel="Close dialog box"
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button variant="normal" onClick={closeRunReportModal}>Close</Button>
                            <Button variant="primary" id="run-report-md" onClick={submitRunReportRequest}
                                    loading={runReportModalButtonLoading}>Run Report</Button>
                        </SpaceBetween>
                    </Box>
                }
                header="Run Report"
            >
                <SpaceBetween direction="vertical" size="l">

                    <div>
                        {
                            showErrorAlert ? (
                                <Alert type="error">
                                    Cannot run report. There is an existing pending or running execution for the
                                    same date range.
                                    To run the report, cancel the overlapping execution and try again or choose a
                                    different date range to run the report for.
                                </Alert>
                            ) : ('')
                        }

                    </div>

                    <RadioGroup
                        onChange={({detail}) => runReportModalOptionClick(detail.value)}
                        value={runReportOption}
                        items={[
                            {
                                value: "lastWeek",
                                label: "Last week only",
                                description: Util.dateToYMDSlashString(DateUtil.getLastSundayDate())
                            },
                            {
                                value: "last10Weeks",
                                label: "Last 10 weeks",
                                description: getLast10WeeksWBRRangeText()
                            },
                            {
                                value: "customDateRange",
                                label: "Custom date range"
                            }
                        ]}
                    />

                    <div>
                        {
                            customDateRangeComponentsDisabled ? '' : (
                                <div>
                                    <FormField
                                        label="Ending report date"
                                    >
                                        <DatePicker
                                            onChange={({detail}) => handleDatePickerClick(detail.value)}
                                            value={reportExecutionDate}
                                            isDateEnabled={date => date.getDay() == 0 && date < new Date()}
                                            nextMonthAriaLabel="Next month"
                                            placeholder="YYYY/MM/DD"
                                            previousMonthAriaLabel="Previous month"
                                            todayAriaLabel="Today"
                                        />
                                    </FormField>

                                    <FormField
                                        label="Previous weeks to run"
                                    >
                                        <ButtonDropdown
                                            ariaLabel={previousWeeksToRunDropdownLabel}
                                            items={lastNWeekDropdownItems}
                                            onItemClick={(event) => handleDateDropdownClick(event.detail.id)}
                                        >
                                            {previousWeeksToRunDropdownLabel}
                                        </ButtonDropdown>

                                    </FormField>
                                </div>
                            )
                        }
                    </div>

                </SpaceBetween>
            </Modal>

            <Modal
                onDismiss={closeCancelModal}
                visible={cancelModalVisible}
                id='cancel-modal'
                closeAriaLabel="Close dialog box"
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button variant="normal" onClick={closeCancelModal}>Back</Button>
                            <Button variant="primary" id="cancel-md" onClick={cancelExecution}
                                    loading={runReportModalButtonLoading}>Cancel execution</Button>
                        </SpaceBetween>
                    </Box>
                }
                header="Cancel execution"
            >
                Are you sure you want to cancel this execution?
            </Modal>
            <Modal
                onDismiss={closeRunAgainModal}
                visible={runAgainModalVisible}
                id="run-again-modal"
                closeAriaLabel="Close dialog box"
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button variant="normal" id="cancel-run-ag" onClick={closeRunAgainModal}>Cancel</Button>
                            <Button variant="primary" id="run-ag-md" onClick={runAgain}
                                    loading={runReportModalButtonLoading}>Run again</Button>
                        </SpaceBetween>
                    </Box>
                }
                header="Run this execution again?"
            >
                <SpaceBetween direction="vertical" size="l">

                    <div>
                        {
                            showErrorAlert ? (
                                <Alert type="error">
                                    Cannot run report. There is an existing pending or running execution for the
                                    same date range.
                                    To run the report, cancel the overlapping execution and try again or choose a
                                    different date range to run the report for.
                                </Alert>
                            ) : ('')
                        }

                    </div>
                    <div>
                        Are you sure you want to run this execution again? It will override currently stored data and regenerate the report for the previous 10 weeks.
                    </div>
                </SpaceBetween>
            </Modal>
            <Table
                {...collectionProps}
                selectionType="single"
                loading={!allExecutions || refreshTable}
                onSelectionChange={({ detail }) =>
                    handleSelect(detail)
                }
                selectedItems={selectedItems}
                header={<Header
                    counter ={
                        allExecutions &&
                        (selectedItems!.length ? `(1/${allExecutions.length})` : `(${allExecutions.length})`)

                    }
                    actions={
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button variant="normal" id="refresh" loading={refreshTable} iconName="refresh" onClick={() => retrieveReportExecutions()}></Button>


                            <Button variant="normal" id="view-result" disabled={viewResultDisabled}
                                    loading={viewResultLoading}
                                    onClick={() => viewResult()}>View Result</Button>

                            {actionButtons}
                            <Button variant="primary" id="run-report" iconName="caret-right-filled"
                                    onClick={() => setRunReportModalVisible(true)}>Run Report</Button>
                        </SpaceBetween>
                    }

                >
                    Run history </Header>
                }
                columnDefinitions={[
                    {
                        id: "wbr_date",
                        header: "Report date",
                        cell: item => item.wbr_date || "-",
                        sortingField: "wbr_date"
                    },
                    {
                        id: "end_datetime",
                        header: "Completed",
                        cell: item => item.end_datetime || "-",
                        sortingField: "end_datetime"
                    },
                    {
                        id: "execution_id",
                        header: "Run ID",
                        cell: item => item.execution_id || "-",
                        sortingField: "execution_id"
                    },
                    {
                        id: "execution_status",
                        header: "Status",
                        cell: item => (statusToIndicator(item.execution_status)),
                        sortingField: "execution_status"
                    }

                ]}
                items={items}
                loadingText="Loading past executions"
                pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels}
                                        onChange={ event  => {
                                            handlePagination(event)
                                            paginationProps.onChange(event);
                                        }}
                />}
                filter={
                    <TextFilter
                        {...filterProps}
                        countText={getMatchesCountText(filteredItemsCount!)}
                        filteringAriaLabel="Filter records"
                    />
                }
                preferences={
                    <CollectionPreferences
                        title="Preferences"
                        confirmLabel="Confirm"
                        cancelLabel="Cancel"
                        preferences={{
                            pageSize: pageSize,
                        }}
                        pageSizePreference={{
                            title: "Select page size",
                            options: [
                                { value: 20, label: "20 executions" },
                                { value: 50, label: "50 executions" },
                                { value: 100, label: "100 executions" }
                            ]
                        }}
                        onConfirm={(event) => setPageSize(event.detail.pageSize as number)}
                    />
                }
            />
            {(cloudFrontUrl != "")  && <iframe style={{border:"none", width: "100%"}} height={"0.1px"} src={cloudFrontUrl}/>}

        </SpaceBetween>



    );
}
